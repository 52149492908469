export enum RewardsUom {
  Dollars = '$',
  Bushels = 'bu',
  Gallons = 'gal',
  Tons = 'ton',
  Acres = 'ac',
}

export const RewardUomMapping: { [rewardUom: string]: string[] } = {
  [RewardsUom.Dollars]: [RewardsUom.Dollars],
  [RewardsUom.Bushels]: [RewardsUom.Bushels],
  [RewardsUom.Gallons]: [RewardsUom.Gallons, 'gallon', 'gallons'],
  [RewardsUom.Tons]: [RewardsUom.Tons],
  [RewardsUom.Acres]: [RewardsUom.Acres],
};

export const parseRewardsUom = (uom: string | null): RewardsUom | null => {
  if (uom === null) {
    return null;
  }
  const rewardsUomValues: string[] = Object.values(RewardsUom);
  for (const rewardUom of rewardsUomValues) {
    const mappedUomValues = RewardUomMapping[rewardUom];
    const rewardMappingRegex = new RegExp(`^(${mappedUomValues.join('|')})$`, 'i');
    if (rewardMappingRegex.test(uom)) {
      return rewardUom as RewardsUom;
    }
  }
  return null;
};
